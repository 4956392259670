<template>
  <div class="container">
    <div class="title">
      <h3>{{ $t("menu.quotation") }}</h3>
    </div>
    <div class="tabsBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="$t('market.digitalCurrency')"
          name="1"
        ></el-tab-pane>
        <el-tab-pane :label="$t('market.nobleMetal')" name="6"></el-tab-pane>
        <el-tab-pane
          :label="$t('market.foreignExchange')"
          name="5"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="tableBox flex-col">
      <el-table
        :empty-text="$t('rankText.emptyText')"
        :data="tableData"
        style="width: 100%"
        size="small"
      >
        <el-table-column prop="name" :label="$t('rankText.transactionType')">
          <template #default="scope">
            <img
              :src="scope.row.logo"
              style="
                width: 30px;
                height: 30px;
                vertical-align: middle;
                margin-right: 5px;
              "
            />
            <template v-if="activeName == '1'">
              <span
                >{{ scope.row.currency_name }}/{{ scope.row.legal_name }}</span
              >
            </template>
            <template v-else>
              <span>{{ scope.row.ptitle }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="nowPrice" :label="$t('rankText.nowPrice')">
          <template #default="scope">
            <template v-if="activeName == '1'">
              <span>{{ scope.row.now_price }}</span>
            </template>
            <template v-else>
              <span>{{ scope.row.productdata.price }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="priceRate" :label="$t('rankText.priceRate')">
          <template #default="scope">
            <template v-if="activeName == '1'">
              <colorRate :rate="scope.row.change"></colorRate>
            </template>
            <template v-else>
              <colorRate
                :rate="scope.row.change"
                colorType="5"
                :isup="scope.row.isup"
              ></colorRate>
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="$t('rankText.operation')">
          <template #default="scope">
            <el-button type="primary" size="small" @click="rowClick(scope.row)">{{
              $t("rankText.futures")
            }}</el-button>
            <el-button type="warning" size="small" @click="contractClick(scope.row)">{{
              $t("menu.contract")
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script setup>
import colorRate from "@/components/common/colorRate.vue";
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "@/utils/vueApi";
import { getQuotation_new, getWhlist } from "@/api/market";
const router = useRouter();
const jumpPage = (url) => {
  router.push(url);
};
const activeName = ref("1");
const handleClick = (e) => {
  digitalCurrency.value = {
    quotation: [],
  };
  metalList.value = [];
  initData();
};
const rowClick = (row) => {
  jumpPage(
    `/marketDetail?id=${
      activeName.value == "1" ? row.id : row.productdata.id
    }&type=${activeName.value}`
  );
};
const contractClick = (row) => {
  jumpPage(
    `/marketContract?id=${
      activeName.value == "1" ? row.id : row.productdata.id
    }&type=${activeName.value}`
  );
}
// 数字货币
const digitalCurrency = ref({
  quotation: [],
});
// 贵金属 、外汇
const metalList = ref([]);
// 获取数据
const initData = async () => {
  if (activeName.value == "1") {
    const { message } = await getQuotation_new();
    digitalCurrency.value = message[0];
  } else {
    const { message } = await getWhlist({
      cid: activeName.value,
    });
    metalList.value = message;
  }
};
const tableData = computed(() => {
  if (activeName.value == "1") {
    return digitalCurrency.value.quotation;
  } else {
    return metalList.value;
  }
});
let timer;
onMounted(() => {
  timer = setInterval(() => {
    initData();
  }, 2000);
});
onUnmounted(() => {
  clearInterval(timer);
})
</script>
<style lang="scss" scoped>
.container {
  .title {
    margin: 40px 20px 20px 20px;
    h3 {
      font-size: 28px;
      font-weight: bold;
    }
  }
  .tabsBox {
    padding-left: 20px;
    box-sizing: border-box;
  }
  .tableBox {
    margin: 30px 0px;
    background-color: $section-color;
    padding: 20px;
    box-sizing: border-box;
    .tableHeader {
      font-size: 14px;
      box-sizing: border-box;
      opacity: 0.6;
      padding: 20px 40px 10px 40px;
    }
    .tableRow {
      padding: 15px 40px;
      box-sizing: border-box;
      border-bottom: 1px solid $border-color;
    }
    .headerItem {
      display: flex;
      align-items: center;
    }
  }
}
</style>
