import service from "@/http";
// 获取秒数
export const getSeconds = (data) => {
  return service({
    url: "/microtrade/seconds",
    method: "get",
    params: data,
  });
};

// 数字货币 ---------------------------------------
export const getQuotation_new = (data) => {
  return service({
    url: "/currency/quotation_new",
    method: "get",
    params: data,
  });
};
// 数字货币-char
export const get_new_timeshar = (data) => {
  return service({
    url: "/currency/new_timeshar",
    method: "get",
    params: data,
  });
};
// 数字货币-做
export const setWhsubmit = (data) => {
  return service({
    url: "/microtrade/submit",
    method: "post",
    data: data,
  });
};

// 数字货币-交易记录
export const getNumLists = (data) => {
  return service({
    url: "/microtrade/lists",
    method: "get",
    params: data,
  });
};

// 数字货币-合约账户
export const get_my_trade = (data) => {
  return service({
    url: "/lever/my_trade",
    method: "post",
    data: data,
  });
}

// 数字货币-平仓
export const numberClose = (data) => {
  return service({
    url: "/lever/close",
    method: "post",
    data: data,
  });
}

// 数字货币-合约-提交
export const numberSubmit = (data) => {
  return service({
    url: "/lever/submit",
    method: "post",
    data: data,
  });
}


// ------------------------
// cid=5是外汇，cid=6是贵金属
export const getWhlist = (data) => {
  return service({
    url: "/currency/whlist",
    method: "get",
    params: data,
  });
};

// 贵金属、外汇-char
export const getWhkChar = (data) => {
  return service({
    url: "/currency/whkdata",
    method: "get",
    params: data,
  });
};
// 贵金属、外汇-做
export const miSubmit = (data) => {
  return service({
    url: "/microtrade/whsubmit",
    method: "post",
    data: data,
  });
};

// 贵金属、外汇-交易记录
export const getwhlistsTable = (data) => {
  return service({
    url: "/microtrade/whlists",
    method: "get",
    params: data,
  });
};

// 贵金属、外汇-合约账户
export const get_wh_trade = (data) => {
  return service({
    url: "/lever/my_wh_trade",
    method: "post",
    data: data,
  });
}

// 贵金属、外汇-平仓
export const whclose = (data) => {
  return service({
    url: "/lever/whclose",
    method: "post",
    data: data,
  });
}


// 贵金属、外汇-下单
export const whsubmit = (data) => {
  return service({
    url: "/lever/whsubmit",
    method: "post",
    data: data,
  });
}